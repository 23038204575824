import json2csv from 'json2csv'
/**
 * 表格排序方法
 */
export function tableSort(front, queen, key) {
    // console.log(front[key], queen[key], key)
    const val = "";
    const before = `${front[key]}`;
    const after = `${queen[key]}`
    if (!front[key]) {
        return val.localeCompare(after);
    } else if (!queen[key]) {
        return before.localeCompare(val);
    } else if (!front[key] && !queen[key]) {
        return val.localeCompare(val);
    } else {
        return before.localeCompare(after);
    }
}

// CSV导出
export function csvDownload(data, columns, fileName, node) {
    try {
        const datas = [];
        const fields = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {
                NO: i + 1,
            };
            for (let j = 0; j < columns.length; j++) {
                if (columns[j].dataIndex !== "NO") {
                    obj[columns[j].title] = data[i][columns[j].dataIndex];
                }
                if (i == 0 && columns[j].dataIndex !== "chaozuo") {
                    fields.push(columns[j].title);
                }
                
            }
            datas.push(obj);
        }
        const result = json2csv.parse(datas, {
            fields: fields
        });
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
            navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
            //IE10或Edge浏览器
            const BOM = "\uFEFF";
            const csvData = new Blob([BOM + result], { type: "text/csv" });
            navigator.msSaveBlob(csvData, `${fileName}.csv`);
        } else {
            //非IE浏览器
            const csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
            //使用a标签的download属性实现下载功能
            const link = document.createElement("a");
            link.href = encodeURI(csvContent);
            link.download = `${fileName}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    } catch (err) {
        alert(err);
    }
}

// 表格表头的显示内容初始化和缓存
export function tableTitleDisplay(name, columns) {
    const tableTitle = JSON.parse(localStorage.getItem(name));
    if (!tableTitle || tableTitle.length == 0) {
        const displayContent = [];
        for (let i = 0; i < columns.length; i++) {
            displayContent.push({
                id: columns[i].id,
                title: columns[i].title,
                select: true,
                disabled:
                    columns[i].dataIndex == "NO"
                        ? true
                        : columns[i].dataIndex == "chaozuo"
                            ? true
                            : false,
            });
        }
        localStorage.setItem(name, JSON.stringify(displayContent));
        return { columns, displayContent }
    } else {
        const displayContent = JSON.parse(localStorage.getItem(name));
        for (let i = 0; i < displayContent.length; i++) {
            let result = columns.findIndex((row) => row.id === displayContent[i].id);
            displayContent[i].title = columns[result].title;
        }
        localStorage.setItem(name, JSON.stringify(displayContent));
        return { displayContent }
    }
}

// 表格表头内容选择和缓存
export function tableIndexDisplay(name, columns, displayContent) {
    const column = [];
    for (let i = 0; i < displayContent.length; i++) {
        if (displayContent[i].select) {
            let result = columns.findIndex((row) => row.id === displayContent[i].id);
            column.push(columns[result]);
        }
    }
    localStorage.setItem(name, JSON.stringify(displayContent));
    return column
}

// 使用iframe框架下载文件--以excel为例，可修改type与fileName选择文件类型
export function downloadUrl(res, name) {
    const blob = new Blob([res], { type: 'application/octet-stream' }) // 构造一个blob对象来处理数据
    const fileName = name // 导出文件名
    const elink = document.createElement('a') // 创建a标签
    elink.download = fileName // a标签添加属性
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click() // 执行下载
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink) // 释放标签
}


export function downloadCSVUrl(CSV, fileName) {
    var fileName = fileName;
    var uri = new Blob(['\ufeff' + CSV], { type: "text/csv" });
    var link = document.createElement("a");
    link.href = URL.createObjectURL(uri);
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}